import React from 'react';
import GatsbyImage from 'gatsby-image';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const ImgWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const YearsWrapper = styled.div`
  flex: 1;
  //overflow-y: scroll;
  height: 100vh;
  width: 100%;
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  overflow-y: auto;
  height: 100%;

  h1 {
    margin: 2rem 0 0.5rem;
    font-size: 2.5rem;
  }

  div {
    margin-top: 2rem;
  }
`;

const Homepage = (props: any) => {
  const entryYearsArray = props.allPrismicYear.edges
    .filter((year: any) => year.node.data.entry_year.text !== '3000')
    .map((res: any) => res.node.data.entry_year.text)
    .sort()
    .reverse();
  return (
    <Wrapper>
      <ImgWrapper>
        <GatsbyImage
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          fluid={props.prismicHomepage.data.hero_image.fluid}
        />
      </ImgWrapper>
      <YearsWrapper>
        <TextContainer>
          <h1>ROH&apos;s Journal</h1>
          {entryYearsArray.map((i: any, index: number) => {
            return (
              <div key={index}>
                <Link to={`/${i}`}>{i}</Link>
              </div>
            );
          })}
        </TextContainer>
      </YearsWrapper>
    </Wrapper>
  );
};

export default Homepage;
