import React from 'react';
import Seo from '../components/Shared/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import Homepage from '../components/Homepage';
import Layout from '../components/Layout/Layout';

export const HOMEPAGE_QUERY = graphql`
  query {
    allPrismicYear {
      edges {
        node {
          data {
            entry_year {
              text
            }
          }
        }
      }
    }
    prismicHomepage {
      data {
        hero_image {
          fluid(maxWidth: 600) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const { allPrismicYear, prismicHomepage } = useStaticQuery(HOMEPAGE_QUERY);
  return (
    <Layout>
      <Seo title="Home" />
      <Homepage
        allPrismicYear={allPrismicYear}
        prismicHomepage={prismicHomepage}
      />
    </Layout>
  );
};

export default IndexPage;
